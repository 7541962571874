import { Link } from 'react-router-dom';

import useHideElements from 'hooks/useHideElements';
import Container from 'components/common/container/Container';

import "./Footer.scss";

function Footer() {
    // logic used to decide in which parts of the app to hide the footer
    const elementsToHide = [
        { location: '/login', element: 'footer' },
        { location: '/signup', element: 'footer' },
        { location: '/beneficiary', element: 'footer' },
    ];

    const styles = useHideElements(elementsToHide);

    return (
        <footer style={styles['footer'] || {}}>
            <Container>
                <div className="footer-header textContent">
                    <div className='anara-logo-footer'>
                        <Link to="/">
                            <img src={process.env.PUBLIC_URL + '/images/anara-logo-white.png'} alt="anara-logo" />
                        </Link>
                    </div>
                    <p className="p-24 white-text">
                        Better care for your family in India.
                    </p>
                    <p className="p-24 white-text">
                        Peace of mind for you.
                    </p>
                </div>
                <hr />
                <div className="footer__links">
                    <p className="p-18 white-text">
                        © {new Date().getFullYear()} Anara Inc. All rights reserved
                    </p>
                    <nav>
                        <ul>
                            <li>
                                <Link to="/termsandconditions">Terms</Link>
                            </li>
                            <li>
                                <Link to="/privacypolicy">Privacy</Link>
                            </li>
                            {/* <li>
                            <a href="/cookies">
                                Cookies
                            </a>
                        </li> */}
                        </ul>
                    </nav>
                </div>
            </Container>
        </footer>
    );
}

export default Footer;
