import Spinner from 'react-bootstrap/Spinner';

const CustomSpinner = ({text}) => {
  return (
    <div style={{display: 'inline-flex'}}>
      <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          variant="secondary"
      />
      {text && <span className="m-l-12">{text}</span>}
    </div>
  );
}

export default CustomSpinner;