import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: "",
  name: "",
  email: "",
  city: "",
  password: "",
  postalCode: "",
  planId: "",
  stripeCustomerId: "",
  // TODO: finish adding all the data to keep it as the initial store
}

const registeringUserSlice = createSlice({
  name: 'registeringUser',
  initialState,
  reducers: {
    setRegisteringUser: (state, action) => {
      return { ...state, ...action.payload }
    },
    resetRegisteringUser: () => initialState,
  }
})

export const { setRegisteringUser, resetRegisteringUser } = registeringUserSlice.actions

export default registeringUserSlice.reducer
