import { createSlice } from '@reduxjs/toolkit'

const initialState = [
  {
    id: "",
    firstName: "",
    lastName: "",
    nickName: "",
    sponsorId: "",
    sponsorshipId: "",
    relation: "",
    contact: {
      street: "",
      number: "",
      city: "",
      email: "",
      phone: "",
    },
    medicalConditions: "",
    city: "",
    password: "",
    postalCode: "",
  },
];

const beneficiariesSlice = createSlice({
  name: 'beneficiaries',
  initialState,
  reducers: {
    setBeneficiary: (state, action) => {
      const index = state.findIndex(beneficiary => beneficiary.id === action.payload.id)
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload }
      } else {
        state.push(action.payload)
      }
    },
    deleteBeneficiary: (state, action) => {
      const index = state.findIndex(beneficiary => beneficiary.id === action.payload.id)
      if (index !== -1) {
        state.splice(index, 1)
      }
    },
    resetBeneficiary: () => initialState,
  }
});

export const { setBeneficiary, deleteBeneficiary, resetBeneficiary } = beneficiariesSlice.actions

export default beneficiariesSlice.reducer
