import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: "",
  name: "",
  email: "",
  displayName: "",
  city: "",
  password: "",
  postalCode: "",
}

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      return { ...state, ...action.payload }
    },
    resetCurrentUser: () => initialState,
  }
})

export const { setCurrentUser, resetCurrentUser } = currentUserSlice.actions

export default currentUserSlice.reducer
