import React from 'react';

const MenuCloseSvg = ({ width = 40, height = 40 }) => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 26.728L26.7279 14.0001" stroke="#4B483F" stroke-width="2" stroke-linecap="round"/>
<path d="M14 14L26.7279 26.7279" stroke="#4B483F" stroke-width="2" stroke-linecap="round"/>
</svg>
    );

export default MenuCloseSvg;
