/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useAuth } from 'reactfire';

import { clearLocalStorage } from 'helpers/utils';
import useLocalStorage from './useLocalStorage';
import { availableEntities } from 'constants/general';

const useAutoSignOut = (timeout) => {
  const auth = useAuth();
  const signOut = a => a.signOut().then(() => console.log('**** signed out ****'));
  const {storedValue: refreshTokenIntervalId } = useLocalStorage(availableEntities.REFRESH_TOKEN_INTERVAL_ID);

  useEffect(() => {
    let timer;
    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        signOut(auth);
        if (refreshTokenIntervalId) {
          try {
            clearInterval(refreshTokenIntervalId);
          } catch (error) {
            console.error('Error clearing refresh token interval:', error);
          }
        }
        clearLocalStorage();
      }, timeout);
    };

    document.onmousemove = resetTimer;
    document.onclick = resetTimer;

    resetTimer();  // Start the timer when the component mounts

    return () => {
      // Clean up event listeners and timer when the component unmounts
      clearTimeout(timer);
      document.onmousemove = null;
      document.onclick = null;
    };
  }, [auth, timeout]);
};

export default useAutoSignOut;
