import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { FirebaseAppProvider } from 'reactfire';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter

import store from './redux/store';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import App from './App';
import { appInsights, reactPlugin } from './telemetry/appInsights';

import reportWebVitals from './reportWebVitals';
import ReactFireProvider from './ReactFireProvider';
import firebaseConfig from './firebase';

import CustomSpinner from 'components/common/Spinner/Spinner';

import './index.scss';

// Conditionally wrap App with Application Insights tracking if appInsights is defined
const TrackedApp = appInsights ? withAITracking(reactPlugin, App) : App;

const root = document.getElementById('root');
ReactDOM.createRoot(root).render(
  <Provider store={store}>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <ReactFireProvider>
        <Suspense fallback={<div className='centered-spinner'><CustomSpinner text={'Loading...'} /></div>}>
          <Router>
            <TrackedApp />
          </Router>
        </Suspense>
      </ReactFireProvider>
    </FirebaseAppProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
