/**
 * Set all the general scope app constants here
 */
const DEVELOPMENT = 'development';
const PRODUCTION = 'production';
const STRIPE = 'stripe';
const FIREBASE = 'firebase';
const HUBSPOT = 'hubspot';
// TODO: define the base url for the api and define one for prod and another for dev/staging
let FIREBASE_API_URL_V0 = `https://firebase-service.ab6e7b1fad104a0c90d8.centralus.aksapp.io/${FIREBASE}`;
let FIREBASE_API_URL_V1 = `https://firebase-service.ab6e7b1fad104a0c90d8.centralus.aksapp.io/v1/${FIREBASE}`;
let STRIPE_API_URL_V1 = `https://stripe-service.ab6e7b1fad104a0c90d8.centralus.aksapp.io/${STRIPE}`;
let HUBSPOT_API_URL_V1 = `https://hubspot-service.ab6e7b1fad104a0c90d8.centralus.aksapp.io/${HUBSPOT}`;
let MANAGE_BILLING_DETAILS_URL =
	'https://billing.stripe.com/p/login/00g2886bt4TPaU8eUU';

if (process.env.NODE_ENV === DEVELOPMENT || process.env.REACT_APP_STAGING_ENV) {
	FIREBASE_API_URL_V0 = `https://firebase-service-test.b3f441aa08a84b6c8eb9.centralus.aksapp.io/${FIREBASE}`;
	FIREBASE_API_URL_V1 = `https://firebase-service-test.b3f441aa08a84b6c8eb9.centralus.aksapp.io/v1/${FIREBASE}`;
	STRIPE_API_URL_V1 = `https://stripe-service-test.b3f441aa08a84b6c8eb9.centralus.aksapp.io/${STRIPE}`;
	HUBSPOT_API_URL_V1 = `https://hubspot-service-test.b3f441aa08a84b6c8eb9.centralus.aksapp.io/${HUBSPOT}`;
	MANAGE_BILLING_DETAILS_URL =
		'https://billing.stripe.com/p/login/00g2886bt4TPaU8eUU';
}

const INDIA_PIN_CODE_LENGTH = 6;
const POSTAL_PIN_CODE_API = 'https://pincode.p.rapidapi.com/';
const IPAPI_URL = 'https://ipapi.co/json/';
const RAPID_API_HOST = 'pincode.p.rapidapi.com';
const RAPID_API_URL = `https://${RAPID_API_HOST}/`;

const INACTIVITY_TIME_AUTO_LOGOUT = 60 * 60 * 1000; // 60 mins of inactivity to logout
const AUTO_REFRESH_TOKEN_TIME = 55 * 60 * 1000; // refresh the firebase auth token every 55 minutes

// methods
const POST = 'POST';
const GET = 'GET';
const PATCH = 'PATCH';
const PUT = 'PUT';

// mirror keys to prevent magic strings use
const TRUE = 'true';
const FALSE = 'false';
const HIDDEN_INPUT = 'hidden-input';
const OTHER = 'other';
const SUBSCRIPTION = 'subscription';
const CARE_PLUS_PLAN = 'Care Plus';
const CARE_PLUS_ID = 'care-plus';
const CARE_ANYWHERE_ID = 'care-anywhere';
const CUSTOM_CARE_ID = 'custom-care';
const INDIA = 'India';
const NEW_LOC_EM_CONTACT = 'new-lec';
const GET_IFRAME_HEIGHT = 'GET_IFRAME_HEIGHT';
const SET_IFRAME_HEIGHT = 'SET_IFRAME_HEIGHT';
const REDIRECT_TO_BLOG_LISTING_PAGE = 'REDIRECT_TO_BLOG_LISTING_PAGE';
const REDIRECT_TO_POST_PAGE = 'REDIRECT_TO_POST_PAGE';
const REDIRECT_TO_CATEGORY_PAGE = 'REDIRECT_TO_CATEGORY_PAGE';
const REDIRECT_TO_BLOG_AUTHOR_PAGE = 'REDIRECT_TO_BLOG_AUTHOR_PAGE';

// localization, currency and country codes
const USA_COUNTRY_CODE = 'US';
const USA_CURRENCY = 'USD';
const CANADA_COUNTRY_CODE = 'CA';
const CANADA_CURRENCY = 'CAD';
const INDIA_COUNTRY_CODE = 'IN';
const INDIA_CURRENCY = 'INR';
const RUPEE_CURRENCY_SIGN = '₹';
const DOLAR_CURRENCY_SIGN = '$';

const availableEntities = {
	REGISTERING_SPONSOR: 'registeringSponsor',
	CURRENT_USER: 'currentUser',
	ADDING_BENEFICIARY: 'addingBeneficiary',
	PLANS: 'plans',
	REGISTERING_BENEFICIARY: 'registeringBeneficiary',
	COUPON_DISCOUNT: 'couponDiscount',
	SPONSOR_ID: 'sponsorId',
	SPONSORSHIP_ID: 'sponsorshipId',
	HUBSPOT_CONTACT_ID: 'hubspotContactId',
	COUNTRY_INFO: 'countryInfo',
	EXTRA_BEN_PLANS: 'extraBenPlans',
	ADD_OTHER_BEN_SELECTED_ADDRESS_ID: 'addOtherBenSelectedAddress',
	ADD_OTHER_BEN_SELECTED_PLAN_NAME: 'addOtherBenSelectedPlanName',
	REFRESH_TOKEN_INTERVAL_ID: 'refreshTokenIntervalId',
	PURCHASE_INFO: 'purchaseInfo'
};

const BLOG_LISTING_PAGE_ORIGIN = process.env.REACT_APP_BLOG_PAGE_ORIGIN;
const BLOG_LISTING_PAGE_URL = `${BLOG_LISTING_PAGE_ORIGIN}/${process.env.REACT_APP_BLOG_LISTING_PAGE_ROUTE}`;

export {
	STRIPE_API_URL_V1,
	FIREBASE_API_URL_V0,
	FIREBASE_API_URL_V1,
	HUBSPOT_API_URL_V1,
	MANAGE_BILLING_DETAILS_URL,
	TRUE,
	FALSE,
	INACTIVITY_TIME_AUTO_LOGOUT,
	POST,
	GET,
	AUTO_REFRESH_TOKEN_TIME,
	PATCH,
	PUT,
	availableEntities,
	DEVELOPMENT,
	POSTAL_PIN_CODE_API,
	INDIA_PIN_CODE_LENGTH,
	IPAPI_URL,
	USA_COUNTRY_CODE,
	USA_CURRENCY,
	CANADA_COUNTRY_CODE,
	CANADA_CURRENCY,
	PRODUCTION,
	INDIA_COUNTRY_CODE,
	INDIA_CURRENCY,
	HIDDEN_INPUT,
	RUPEE_CURRENCY_SIGN,
	DOLAR_CURRENCY_SIGN,
	OTHER,
	SUBSCRIPTION,
	CARE_PLUS_PLAN,
	CUSTOM_CARE_ID,
	CARE_PLUS_ID,
	CARE_ANYWHERE_ID,
	RAPID_API_URL,
	RAPID_API_HOST,
	INDIA,
	NEW_LOC_EM_CONTACT,
	GET_IFRAME_HEIGHT,
	SET_IFRAME_HEIGHT,
	REDIRECT_TO_BLOG_LISTING_PAGE,
	REDIRECT_TO_POST_PAGE,
	REDIRECT_TO_CATEGORY_PAGE,
	BLOG_LISTING_PAGE_ORIGIN,
	BLOG_LISTING_PAGE_URL,
	REDIRECT_TO_BLOG_AUTHOR_PAGE
};

export const PORTAL_REDIRECT_URL = 'https://kind-wave-0fa24291e.5.azurestaticapps.net';
