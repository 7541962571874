import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { PRODUCTION } from 'constants/general';



const reactPlugin = new ReactPlugin();
let appInsights;

if (process.env.NODE_ENV === PRODUCTION) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY,
        extensions: [reactPlugin],
        // Adjusted extensionConfig if necessary
      }
    });
    appInsights.loadAppInsights();
}


export { appInsights, reactPlugin };