import { useLocation } from 'react-router-dom';

const useHideElements = (elementsToHide) => {
    const location = useLocation();
    const hiddenElements = elementsToHide.filter(el => el.location === location.pathname);
    const styles = hiddenElements.reduce((acc, el) => {
      acc[el.element] = { display: 'none' };
      return acc;
    }, {});
  
    return styles;
  };

export default useHideElements;
