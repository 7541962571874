import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../container/Container';

import './AnaraLogo.scss';

const AnaraLogo = ({ action }) => {

    const handleOnClick = () => {
        action?.();
    };

    return (
        <Container>
            <div className='AnaraLogo'>
                <Link to="/" onClick={handleOnClick}>
                    <img src={process.env.PUBLIC_URL + '/images/anara-logo.png'} alt="anara-logo" />
                </Link>
            </div>
        </Container>
    );
}

export default AnaraLogo;
