import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';

import Authentication from '../Auth/Authentication';

import MenuButtonSvg from 'components/svg_images/menu_button_svg';
import MenuCloseSvg from 'components/svg_images/menu_close_svg';
import AnaraLogo from 'components/common/anaraLogo/AnaraLogo';

import useHideElements from 'hooks/useHideElements';

import './Header.scss';
import Container from 'components/common/container/Container';

function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const { data: signInCheckResult } = useSigninCheck();

    const elementsToHide = [
        { location: '/login', element: 'header' },
        { location: '/signup', element: 'header' },
        { location: '/beneficiary', element: 'header' },
    ];

    const styles = useHideElements(elementsToHide);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        // Toggle body scroll
        if (!isOpen) {
            // If the menu is being opened, disable body scroll
            document.body.style.overflow = 'hidden';
        } else {
            // If the menu is being closed, enable body scroll
            document.body.style.overflow = 'auto';
        }
    };

    const links = [
        { id: 'home', to: (<Link to="/">Home</Link>), props: {} },
        { id: 'plans', to: (<Link to="/plans">Plans & Pricing</Link>), props: {} },
        { id: 'blogs', to: (<Link to="/blog">Resources</Link>), props: {} },
        { id: 'aboutus', to: (<Link to="/aboutus">About Us</Link>), props: {} },
        { id: 'contactus', to: (<Link to="/contactus">Contact Us</Link>), props: {} },
        { id: 'auth', to: (<Authentication showAsLink={true} classes={'nav-links'} signUpBtnText='Sign Up' />), props: {} },
    ];

    // Here set the links that must be guarded by authentication
    if (signInCheckResult?.signedIn === true) {
        links.push({ to: (<Link to="/account">My Account</Link>), props: {} });
    }

    return (
        <header className='header' style={styles['header'] || {}}>
            <Container>
                <AnaraLogo />
                <div className='menu'>
                    <Authentication showAsLink={false} classes={`auth-header-buttons ${isOpen ? 'hidden' : ''}`} />
                    <button onClick={toggleMenu} className="hamburger-menu as-link">
                        {isOpen ? <MenuCloseSvg /> : <MenuButtonSvg />}
                    </button>
                </div>
                <nav className={`nav-menu ${isOpen ? 'open' : ''}`}>
                    <Container>
                        <ul>
                            {links.map((link) => (
                                <li
                                    onClick={toggleMenu}
                                    key={link.id}
                                    {...link.props}
                                >
                                    {link.to}
                                </li>
                            ))}
                            <li><Link to="/login" className='sign-in'>Log In</Link></li>
                        </ul>
                    </Container>
                </nav>
            </Container>
        </header>
    );
}

export default Header;