/**
 * Central place where actions for the entities used in the app will be stored.
 * The availableEntities will contain the list of entities that will return the 
 * two methods save and reset. Save will save in redux and in local storage. Reset
 * will reset these two values.
 */
import { useDispatch } from 'react-redux';

import { setRegisteringUser, resetRegisteringUser } from '../redux/slices/registeringUserSlice';
import { setCurrentUser, resetCurrentUser } from '../redux/slices/currentUserSlice';
import { setPlans, resetPlans } from '../redux/slices/plansSlice';
import { availableEntities } from '../constants/general';

import useLocalStorage from './useLocalStorage';

// TODO: review this as the app grows and measure performance on mobile devices
const useSaveEntityInReduxAndLS = (entity) => {
    const dispatch = useDispatch();
    const { setLocalStorageValue, removeLocalStorageValue } = useLocalStorage(entity, {});

    /**
     * save and reset methods for Sponsor (used during sign up)
     * @param {*} sponsorData 
     */
    const saveRegisteringSponsorInStore = async (sponsorData, from='') => {
        try {
            // store in the redux store
            await dispatch(setRegisteringUser(sponsorData));
            // store in the local storage since
            // and the thank you page might require some information and probably the beneficiaries page.
            await setLocalStorageValue(sponsorData);
        } catch (error) {
            console.error('There was an error storing the sponsor data', error);
        }
    };

    const resetRegisteringSponsorInStore = async () => {
        try {
            // store in the redux store
            await dispatch(resetRegisteringUser());
            // store in the local storage since
            await removeLocalStorageValue();
        } catch (error) {
            console.error('Reset - There was an error storing the sponsor data', error);
        }
    };

    /**
     * save and reset methods for User (used during log in)
     * @param {*} userData 
     */
    const saveCurrentUserInStore = async (userData) => {
        try {
            // store in the redux store
            await dispatch(setCurrentUser(userData));
            // store in the local storage since
            await setLocalStorageValue(userData);
        } catch (error) {
            console.error('There was an error storing the sponsor data', error);
        }
    };

    const resetCurrentUserInStore = async () => {
        try {
            // store in the redux store
            await dispatch(resetCurrentUser());
            // store in the local storage since
            await setLocalStorageValue({});
        } catch (error) {
            console.error('Reset - There was an error storing the sponsor data', error);
        }
    };

    /**
     * save and reset methods for User (used during log in)
     * @param {*} plansData 
     */
    const savePlansInStore = async (plansData) => {
        try {
            // store in the redux store
            await dispatch(setPlans(plansData));
            // store in the local storage since
            await setLocalStorageValue(plansData);
        } catch (error) {
            console.error('There was an error storing the plans data', error);
        }
    };

    const resetPlansInStore = async () => {
        try {
            // store in the redux store
            await dispatch(resetPlans());
            // store in the local storage since
            await setLocalStorageValue({});
        } catch (error) {
            console.error('Reset - There was an error storing the plans data', error);
        }
    };

    /**
     * Export sections
     */
    const exportedFunctionsPerEntity = new Map();

    // registering sponsor
    exportedFunctionsPerEntity.set(availableEntities.REGISTERING_SPONSOR, {
        saveRegisteringSponsorInStore,
        resetRegisteringSponsorInStore,
    });

    // current user
    exportedFunctionsPerEntity.set(availableEntities.CURRENT_USER, {
        saveCurrentUserInStore,
        resetCurrentUserInStore,
    });

    // plans
    exportedFunctionsPerEntity.set(availableEntities.PLANS, {
        savePlansInStore,
        resetPlansInStore,
    });

    // TODO: find the way to export always the same two names, saveInStore and resetInStore

    return exportedFunctionsPerEntity.get(entity);
};

export default useSaveEntityInReduxAndLS;
