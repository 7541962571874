// Utility functions across the app,as it grows it can be splitted by AOR
import React from 'react';
import { NEW_LOC_EM_CONTACT } from 'constants/general';
import { Link } from 'react-router-dom';
import Plan from 'components/plansContainer/plan/Plan';
import CheckSVG from 'components/svg_images/purple_circle_check_svg';
import { appInsights } from '../telemetry/appInsights';

/**
 * 
 * @param {*} beneficiaryData could be plain as the first option, coming from the form (TBD if continue this way or always, include the forms map as the second expectation)
 * the second expectation meaning camelCase values and nested objects like address (beneficiaryData.address) or beneficiaryDetails (beneficiaryData.Details). The second
 * expectation will be more expected from modification forms which the object is mapped with the new set values.
 * @returns 
 */
export const mapStateFormToBeneficiaryStructure = (beneficiaryData) => {
  const benMap = {
    firstName: beneficiaryData.firstname || beneficiaryData.firstName || "",
    lastName: beneficiaryData.lastname || beneficiaryData.lastName || "",
    nickName: beneficiaryData.nickname || "",
    relation: beneficiaryData.relation || "",
    otherRelation: beneficiaryData.otherrelation || beneficiaryData.otherRelation || "",
    email: beneficiaryData.email,
    phone: beneficiaryData.phonenumber || beneficiaryData.phone_number,
    sex: beneficiaryData.sex,
    birthdate: beneficiaryData.birthdate,
    address: {
      line1: beneficiaryData.address1 || beneficiaryData.address?.address1 || "",
      line2: beneficiaryData.address2 || beneficiaryData.address?.address2 || "",
      line3: beneficiaryData.address3 || beneficiaryData.address?.address3 || "",
      city: beneficiaryData.city || beneficiaryData.address?.city || "",
      country: beneficiaryData.country || beneficiaryData.address?.country || "",
      state: beneficiaryData.state || beneficiaryData.address?.state || "",
      zip: beneficiaryData.zip || beneficiaryData.address?.zip || "",
    },
    beneficiaryDetails: {
      medicalConditions: beneficiaryData.medicalconditions || beneficiaryData.beneficiaryDetails?.medicalconditions || "",
      healthConcerns: beneficiaryData.healthconcerns || beneficiaryData.beneficiaryDetails?.healthconcerns || "",
      address: {
        line1: beneficiaryData.line1 || beneficiaryData.beneficiaryDetails?.service_address1 || "",
        line2: beneficiaryData.line2 || beneficiaryData.beneficiaryDetails?.service_address2 || "",
        line3: beneficiaryData.line3 || beneficiaryData.beneficiaryDetails?.service_address3 || "",
        city: beneficiaryData.city || beneficiaryData.beneficiaryDetails?.service_city || "",
        country: beneficiaryData.country || beneficiaryData.beneficiaryDetails?.service_country || "",
        state: beneficiaryData.state || beneficiaryData.beneficiaryDetails?.service_state || "",
        zip: beneficiaryData.zip || beneficiaryData.beneficiaryDetails?.service_zip || "",
      },
    },
  };
  if (beneficiaryData.emergencycontact && beneficiaryData.emergencycontact !== NEW_LOC_EM_CONTACT) {
    benMap.beneficiaryDetails.emergencyContactId = beneficiaryData.emergencycontact;
  } else {
    benMap.beneficiaryDetails.emergencyContactDetails = {
      name: beneficiaryData.emergencycontactfirstname ? `${beneficiaryData.emergencycontactfirstname} ${beneficiaryData.emergencycontactlastname}` : `${beneficiaryData.emergencyContactDetails?.firstName} ${beneficiaryData.emergencyContactDetails?.lastName}`,
      firstName: beneficiaryData.emergencycontactfirstname || beneficiaryData.emergencyContactDetails?.firstName || "",
      lastName: beneficiaryData.emergencycontactlastname || beneficiaryData.emergencyContactDetails?.lastName || "",
      email: beneficiaryData.emergencycontactemail || beneficiaryData.emergencyContactDetails?.email || "",
      phone: beneficiaryData.emergencycontactphonenumber || beneficiaryData.emergencyContactDetails?.phone || beneficiaryData.phonenumber || beneficiaryData.emergencyContactDetails?.firstName,
      relation: beneficiaryData.emergencycontactrelation || beneficiaryData.emergencyContactDetails?.relation || "",
      otheRelation: beneficiaryData.emergencycontactotherrelation || beneficiaryData.emergencyContactDetails?.otherRelation || "",
      address: {
        line1: beneficiaryData.emergencycontactline1 || beneficiaryData.emergencyContactDetails?.address?.line1 || "",
        line2: beneficiaryData.emergencycontactline2 || beneficiaryData.emergencyContactDetails?.address?.line2 || "",
        line3: beneficiaryData.emergencycontactline3 || beneficiaryData.emergencyContactDetails?.address?.line3 || "",
        city: beneficiaryData.emergencycontactcity || beneficiaryData.emergencyContactDetails?.address?.city || "",
        country: beneficiaryData.emergencycontactcountry || beneficiaryData.emergencyContactDetails?.address?.country || "",
        state: beneficiaryData.emergencycontactstate || beneficiaryData.emergencyContactDetails?.address?.state || "",
        zip: beneficiaryData.emergencycontactzip || beneficiaryData.emergencyContactDetails?.address?.zip || "",
      },
    }
  }

  return benMap;
};

export const mapSponsorDataToFirebaseFormat = (sponsor) => {
  return {
    firstName: sponsor.name || sponsor.firstname || '',
    lastName: sponsor.lastname || '',
    password: sponsor.password || '',
    email: sponsor.email || '',
    phone: sponsor.phone || sponsor.phonenumber || sponsor.phone_number || '',
    stripeCustomerId: sponsor.stripeCustomerId || sponsor.stripe_customer_id || '',
    address: {
      city: sponsor.city || '',
      country: sponsor.country || '',
      line1: sponsor.line1 || sponsor.address1 || '',
      line2: sponsor.line2 || sponsor.address2 || '',
      line3: sponsor.line3 || sponsor.address3 || '',
      zip: sponsor.zip || '',
      state: sponsor.state || ''
    }
  }
};

export const getAgreePolicyLabel = (
  <span className="agree-privacy-policy">
    You agree to our <Link to="/privacypolicy">privacy policy</Link>.
  </span>
);

export const validateSectionInputs = (rows, formState, validationFormState) => {
  let errors = [];
  rows.forEach(row => {
    row.inputs.forEach(input => {
      if (validationFormState[input.name]) {
        errors.push(`${input.label} ${validationFormState[input.name]}`);
      } else if (input.required && !formState[input.name] && !validationFormState[input.name]) {
        errors.push(`${input.label} is required`);
      }
    });
  });

  if (errors.length > 0) {
    let errorMessage = (
      <div>
        <p>The form is invalid, please correct these errors:</p>
        <ul>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </div>
    );
    return {isValid: false, errorMessage};
  } else {
    return {isValid: true, errorMessage: null};
  }
};

export const getPlanPriceForCountry = (plan, currency) => {
  if (!plan?.prices?.data?.length) {
    return null;
  }

  const price = plan.prices.data.find((price) => price.currency?.toLowerCase() === currency?.toLowerCase());
  return price;
};

/**
 * Formats an address object into a string to be easily printed.
 * @param {Object} address - The address object to format.
 * @param {string} address.line1 - The first line of the address (mandatory).
 * @param {string} [address.line2] - The second line of the address.
 * @param {string} [address.line3] - The third line of the address.
 * @param {string} address.city - The city (mandatory).
 * @param {string} [address.state] - The state.
 * @param {string} [address.country] - The country.
 * @param {string} [address.zip] - The ZIP code.
 * @returns {string} - The formatted address string.
 */
export function formatAddressToPrint(address, shortAddress) {
  const {
    line1 = '',
    line2 = '',
    line3 = '',
    city = '',
    state = '',
    country = '',
    zip = '',
  } = address;

  if (shortAddress) {
    const addressArray = [line1, city, state];
    const formattedAddress = addressArray.filter(Boolean).join(', ');
  
    return formattedAddress;
  }

  const addressArray = [line1, line2, line3, city, state, country, zip];
  const formattedAddress = addressArray.filter(Boolean).join(', ');

  return formattedAddress;
}

export function clearLocalStorage() {
  for (const key in localStorage) {
    if (localStorage.hasOwnProperty(key)) {
      localStorage.removeItem(key);
    }
  }
}


export const relationOptions = [
  { value: 'parent', label: 'Parent' },
  { value: 'grandparent', label: 'Grandparent' },
  { value: 'sibling', label: 'Sibling' },
  { value: 'aunt_uncle', label: 'Aunt/Uncle' },
  { value: 'in_law', label: 'In Law' },
  { value: 'cousin', label: 'Cousin' },
  { value: 'other', label: 'Other' },
];

export const addBeneficiaryRelationOptions = [
  { value: 'child', label: 'Child' },
  { value: 'sibling', label: 'Sibling' },
  { value: 'neighbor', label: 'Neighbor' },
  { value: 'friend', label: 'Friend' },
  { value: 'cousin', label: 'Cousin' },
  { value: 'aunt_uncle', label: 'Aunt/Uncle' },
  { value: 'niece_nephew', label: 'Niece/Nephew' },
  { value: 'other', label: 'Other (if other, please specify)' },
];

export const sexOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

export const dayOptions = Array.from({ length: 31 }, (_, index) => ({
  value: String(index + 1).padStart(2, '0'),
  label: String(index + 1).padStart(2, '0'),
}));

export const monthOptions = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

const currentYear = new Date().getFullYear();
export const yearOptions = Array.from({ length: 100 }, (_, index) => {
  const year = currentYear - index;
  return {
    value: String(year),
    label: String(year),
  };
});

export function convertAndFormatDate(input) {
  if (typeof input === 'string') {
    // convert to timestamp
    const [month, day, year] = input.split('/');
    const parsedMonth = parseInt(month, 10);
    const parsedDay = parseInt(day, 10);
    const parsedYear = parseInt(year, 10);

    // Check if the parsed input elements are valid
    if (isNaN(parsedMonth) || isNaN(parsedDay) || isNaN(parsedYear)) {
      console.error('Invalid date string format');
      return null;
    }

    const timestamp = new Date(`${month}/${day}/${year}`).getTime();
    return timestamp;
  } else if (typeof input === 'number') {
    // Convert from timestamp to formatted date string (MM/DD/YYYY)
    if (isNaN(input)) {
      console.error('Invalid timestamp');
      return null;
    }

    const date = new Date(input);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  } else {
    console.error('Invalid input type');
    return null;
  }
}

export function getDateElements(dateString) {
  const [month, day, year] = dateString.split('/').map(Number);
  if (isNaN(month) || isNaN(day) || isNaN(year)) {
    console.error('Invalid date string format');
    return null;
  }
  return {
    month,
    day,
    year,
  };
}

export const getEmergencyContactInputLabel = (lec) => {
  return (
    <div className='RadioButtonLegend'>
      {(lec.firstName || lec.lastName) && <div className='RadioButtonLegend__name'>
        <span className='span-18-bolder'>
          {lec.firstName ?? ''} {' '} {lec.lastName ?? ''}
        </span>
      </div>}
      {lec.address && <div className='RadioButtonLegend__address'>
        <span className='span-18'>
          {formatAddressToPrint(lec.address, true)}
        </span>
      </div>}
      {lec.phone && <div className='RadioButtonLegend__phone'>
        <span className='span-18'>
          {lec.phone}
        </span>
      </div>}
    </div>
  );
};

export const isMobileScreen = () => {
  const userAgent = navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const isSmallScreen = window.innerWidth <= 768;
  
  return Boolean(isMobile && isSmallScreen)
}

export const formatAndThrowError = (error, errMsgString, forceErrMsg = false) => {
    if (appInsights) {
        appInsights?.trackException({ exception: errMsgString });
    }
    console.log('Formatted Error: ', errMsgString, error);
    if (forceErrMsg) {
      throw new Error(errMsgString);
    }

    if (typeof error === 'string') {
      throw new Error(`${errMsgString} Error: ${error}`);
    } else if (!error?.message || typeof error?.message !== 'string') {
      error.message = errMsgString;
    } else if (error?.message && error.message.toLowerCase() === 'failed to fetch') {
      error.message = `${errMsgString} Error: ${error.message}`;
    }

    throw error;
}

export const extractExistingId = (metadata) => metadata.existingId;
export const isHSContactLead = (metadata) => metadata.lifeCycleStage === 'lead';
export const isStripeCustomerWithPaidSubscription = (metadata) => metadata.hasActiveSubscription === true;

export const carePlusDescription = "Best for seniors who need an annual checkup, and occasional home visits. Additional services available a la carte.\n\nCare Concierge with Anara's trusted Care Team, with **one Care Manager home visit per month.** Get a free Apollo ProHealth Wellness Check."
export const careCompleteDescription = "Best for seniors with multiple conditions, who need an annual checkup and frequent home visits.\n\nAll from Care Plus, and **up to four Care Manager home visits per month. Plus additional home provider visits.** Get a free Apollo ProHealth Wellness Check!"

export const carePlusFeatures = [
  { "Availability": "Chennai, Delhi, Bangalore, Chandigarh, Hyderabad, and more" },
  { "Dedicated Care Manager": "Via phone & in person" },
  { "Care Manager home visits": "1 per month" },
  { "Doctor home visits": "Up to 1 per month" },
  { "Home nurse physiotherapy or other provider visits": "(a la carte)" },
  { "Emergency care plan & support": 1 },
  { "Yearly Apollo ProHealth wellness exam": 1 },
  { "Doctors on call (9am-5pm IST)": 1 },
  { "Priority appointment scheduling & Care Manager accompaniment": 1 },  
  { "Expert health & safety review and second opinions": 1 },
  { "Work with your existing doctors": 1 },
  { "Health updates & support to Sponsor": 1 },
  { "Apollo Best Price Guarantee for procedures and testing": 1 }
];

export const careCompleteFeatures = [
  { "Availability": "Chennai, Delhi, Bangalore, Chandigarh, Hyderabad, and more" },
  { "Dedicated Care Manager": "Via phone & in person" },
  { "Care Manager home visits": "Up to 4 per month" },
  { "Doctor home visits": "1 per month" },
  { "Home nurse physiotherapy or other provider visits": "Up to 4 per month" },
  { "Emergency care plan & support": 1 },
  { "Yearly Apollo ProHealth wellness exam": 1 },
  { "Doctors on call (9am-5pm IST)": 1 },
  { "Priority appointment scheduling & Care Manager accompaniment": 1 },  
  { "Expert health & safety review and second opinions": 1 },
  { "Work with your existing doctors": 1 },
  { "Health updates & support to Sponsor": 1 },
  { "Apollo Best Price Guarantee for procedures and testing": 1 }
];

export const checkSVG = (<CheckSVG />);

export const generatePlanTable = (plan, planKey, planFeatures, planProps) => {
  return (
    <div className='plan-table'>
      <table>
        <tbody>
          <tr>
            <td colSpan={2}>
              <Plan
                plan={plan}
                key={planKey}
                {...planProps}
              />
            </td>
          </tr>
          {planFeatures.map((obj, _) => {
            const key = Object.keys(obj)[0];
            let value = Object.values(obj)[0];

            if (typeof value !== 'string') {
              value = value ? checkSVG : '-';
            }

            return (
              <tr key={key} className='plan-feature-rows'>
                <td className="span-16-bolder plan-feature-key">{key}</td>
                <td className='plan-feature-value'>{value}</td>
              </tr>
            );
          })}
          <tr className='bottom-buttons-row'>
            <td colSpan={2}>
              <Plan
                plan={plan}
                key={`${planKey}-button`}
                onlyPlanButton={true}
                {...planProps}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export const getCookie = (name) => {
  function escapeRegExpChars(s) { return s.replace(/([.*+?^$(){}|[\]/\\])/g, '\\$1'); }
  var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escapeRegExpChars(name) + '=([^;]*)'));
  return match ? match[1] : null;
}

export const parseDescription = (description) => {
  const boldRegex = /\*\*(.*?)\*\*/g;
  return description.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line.split(boldRegex).map((part, partIndex) => 
        partIndex % 2 === 1 ? <strong key={partIndex}>{part}</strong> : part
      )}
      <br />
    </React.Fragment>
  ));
};
