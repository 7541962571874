/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CheckSVG from '../../svg_images/purple_circle_check_svg';

import useSaveEntityInReduxAndLS from 'hooks/useSaveEntityReduxAndLS';
import useLocalStorage from 'hooks/useLocalStorage';

import { availableEntities, CANADA_CURRENCY, CUSTOM_CARE_ID, DEVELOPMENT, DOLAR_CURRENCY_SIGN, INDIA_CURRENCY, RUPEE_CURRENCY_SIGN } from 'constants/general';

import Button from 'components/common/button/Button';

import { getPlanPriceForCountry, carePlusDescription, careCompleteDescription, parseDescription } from 'helpers/utils';
import { appInsights } from '../../../telemetry/appInsights';

import './Plan.scss';

const Plan = ({
    plan,
    customBtnAction,
    customBtnText,
    countryCurrency,
    showFeatureList = false,
    onlyPlanButton = false,
    showHorizontalPlan = false,
    showPlanFeaturesInformation = false,
}) => {
    const {
        id,
        metadata,
        default_price,
        name,
        description,
        features,
    } = plan;

    const [isLoading, setisLoading] = useState(false);
    const navigate = useNavigate();
    const { saveRegisteringSponsorInStore, resetRegisteringSponsorInStore } = useSaveEntityInReduxAndLS(availableEntities.REGISTERING_SPONSOR);
    const { storedValue: discount } = useLocalStorage(availableEntities.COUPON_DISCOUNT);

    let appliedDiscount = discount;

    let price = default_price;

    // TODO: update this when we have support in the BE for the plan prices based on user currency
    if (countryCurrency === CANADA_CURRENCY || countryCurrency === INDIA_CURRENCY) {
        price = getPlanPriceForCountry(plan, countryCurrency);
    }

    let moneySym = DOLAR_CURRENCY_SIGN;
    if (countryCurrency === INDIA_CURRENCY) {
        moneySym = RUPEE_CURRENCY_SIGN;
    }

    if (countryCurrency === CANADA_CURRENCY) {
        moneySym = 'CA$';
    }

    const originalPrice = price?.unit_amount ? (price?.unit_amount / 100) : metadata.customPrice;
    let planPriceWithCoupon = null;
    // temporal hack to show half of the price
    // TODO: This should be more easily customizable
    let discountCode100Off = process.env.REACT_APP_LANDING_PAGE_SPECIAL_OFFER;
    if (process.env.NODE_ENV === DEVELOPMENT || process.env.REACT_APP_STAGING_ENV) {
        discountCode100Off = process.env.REACT_APP_LANDING_PAGE_SPECIAL_OFFER_DEV;
    }

    if (!appliedDiscount) {
        appliedDiscount = discountCode100Off;
    }

    let discountText = null;
    if (originalPrice !== 'Custom Pricing') { // the plans displays products (potentially with discounts)
        if (appliedDiscount === discountCode100Off) {
            discountText = <p className='p-20-bolder text-gray-200'>First month free </p>;
            planPriceWithCoupon = '0';

        }
    } else {
        moneySym = '';
    }

    useEffect(() => {
        // Assuming 'plan' contains all the necessary details
        // Refactored to use GTM events for 'view_item'
        if (window.dataLayer) {
            window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object as recommended
            window.dataLayer.push({
                event: 'view_item',
                ecommerce: {
                    items: [{
                        item_id: plan.id,
                        item_name: plan.name,
                        currency: countryCurrency,
                        price: plan.default_price,
                        discount: appliedDiscount,
                    }]
                }
            });
        }
    }, [plan]); // Ensure this effect runs only when the plan prop changes


    const billingCycle = price?.recurring?.interval || metadata.billingCycle || '';
    let planDescription;
    if (plan.name === 'Care Plus') {
        planDescription = parseDescription(carePlusDescription);
    } else if (plan.name === 'Care Complete') {
        planDescription = parseDescription(careCompleteDescription);
    } else {
        planDescription = metadata.description || description;
    }
    const btnTitle = `Choose ${name}`;
    const btnClass = metadata.btnClass || 'primary-button';
    const planFeatures = typeof metadata.features === "string" ? JSON.parse(metadata.features) : features;


    const checkSVG = (<CheckSVG />);

    const redirectToSignUPOrAddBeneficiary = async (priceId, name) => {
        try {
            // first reset the state of the sponsor
            await resetRegisteringSponsorInStore();
            // first store the planId (priceId) in the global state
            await saveRegisteringSponsorInStore({ planId: priceId, planName: name, totalPaid: planPriceWithCoupon });

            navigate('/signup');
        } catch (error) {
            console.error('There was an error redirecting to sign up page', error);
        }
    };

    const btnAction = async () => {
        setisLoading(true);

        // Send the add_to_cart event
        if (window.dataLayer) {
            window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object as recommended
            window.dataLayer.push({
                event: 'add_to_cart',
                ecommerce: {
                    currency: countryCurrency,
                    value: plan.default_price, // Assuming value is the price of the item
                    items: [{
                        item_id: plan.id,
                        item_name: plan.name,
                        price: plan.default_price,
                        discount: appliedDiscount,
                        quantity: 1 // Assuming a quantity of 1 for simplicity
                    }]
                }
            });
        }

        // little hack for getting the button showing the isLoading and getting disabled
        setTimeout(async () => {
            if (customBtnAction) {
                const res = await customBtnAction();
                return res;
            }
            try {
                if (price?.id || metadata?.defaultPriceId) {
                    if (appInsights) {
                        // Track the button click with Application Insights
                        appInsights?.trackEvent({ name: 'Plan button clicked', properties: { planId: price.id || metadata.defaultPriceId, planName: name } });
                    }

                    redirectToSignUPOrAddBeneficiary(price.id || metadata.defaultPriceId, name);
                } else if (metadata.action === 'custom-care-flow') {
                    if (appInsights) {
                        // Track the button click with Application Insights
                        appInsights?.trackEvent({ name: 'Plan button clicked', properties: { planId: CUSTOM_CARE_ID, planName: 'Care Complete' } });
                    }
                    // TODO: put all these annoying prompttofailure magic strings in constants values
                    await saveRegisteringSponsorInStore({ planId: CUSTOM_CARE_ID, planName: 'Care Complete' });
                    navigate('/signup');
                }
            } catch (error) {
                console.error('There was an error with the button action', error);
                return false;
            }
            return true;
        }, 250);
    };

    let priceSection = (
        <div className='Plan__header--price'>
            <span className={`Plan__header--price--total${originalPrice.length > 5 ? '-smaller' : ''}`}>
                {`${moneySym}${originalPrice}`}
            </span>
            <span className='Plan__header--price--billing-cycle'>
                {billingCycle ? `per ${billingCycle}` : ''}
            </span>
        </div>
    );

    if (appliedDiscount && planPriceWithCoupon) {
        priceSection = (
            <div className='Plan__header--price'>
                <span className={`strikethrough Plan__header--price--total-smaller gray-text-200`}>
                    {`${moneySym}${originalPrice}`}
                </span>
                <span className='strikethrough Plan__header--price--billing-cycle gray-text-200'>
                    {billingCycle ? `per ${billingCycle}` : ''}
                </span>
                <div className='m-t-16'>
                    <span className='span-18-bolder'>
                        Special introductory price
                    </span>
                </div>
                <div>
                    <span className='Plan__header--price--total'>
                        {`${moneySym}${planPriceWithCoupon}`}
                    </span>
                </div>
                {discountText ?? null}
            </div>
        );
    }

    const planButton = (
        <Button
            classes={`Plan__header--start-button ${btnClass}`}
            onClick={btnAction}
            loading={isLoading}
        >
            {customBtnText || btnTitle}
        </Button>
    );

    const horizontalPlan = (
        <div className='Plan horizontal-plan' key={id}>
            <div className='horizontal-plan-info'>
                <div className='header'>
                    <p className='p-30 title'>
                        {name}
                    </p>
                    <p className='description' >
                        {planDescription}
                    </p>
                {planButton}
                </div>
            </div>
            <div className='horizontal-plan-features'>
                <p className='p-18-bolder m-b-24'>
                    Options Include:
                </p>
                <ul className='Plan__features--list'>
                {planFeatures?.list ? planFeatures.list.map((feature) => <li key={String(feature).replace(/\s/g, '')}>{checkSVG}<span>{String(feature)}</span></li>) : []}
                </ul>
            </div>
        </div>
    );

    if (showHorizontalPlan) {
        return horizontalPlan;
    }

    if (onlyPlanButton) {
        return planButton;
    }

    return (
        <div className='Plan' key={id}>
            <div className='Plan__header'>
                <p className='Plan__header--title'>
                    {name}
                </p>
                {priceSection}
                <p className='Plan__header--description'>
                    {planDescription}
                </p>
                {planButton}
            </div>
            {showFeatureList && <div className='Plan__features'>
                <h3 className='Plan__features--title subtitle-h3-16-bolder'>
                    Features
                </h3>
                <p className='Plan__features--description'>
                    {planFeatures?.description}
                </p>
                <ul className='Plan__features--list'>
                    {planFeatures?.list.map((feature) => <li key={feature.replace(/\s/g, '')}>{checkSVG}<span>{feature}</span></li>)}
                </ul>
            </div>}
            {showPlanFeaturesInformation && planFeatures?.information && <div className='Plan__features--information a-le-carte p-16'>{planFeatures?.information}</div>}
        </div>
    );
}

export default Plan;
