import React from 'react';

import {useFirebaseApp, AuthProvider, StorageProvider, FirestoreProvider} from 'reactfire';

import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from "firebase/auth";


const ReactFireProvider = ({ children }) => {
  const app = useFirebaseApp();
  const firestore = getFirestore(app);
  const auth = getAuth(app);
  const storage = getStorage(app);

  return (
    <FirestoreProvider sdk={firestore}>
      <StorageProvider sdk={storage}>
        <AuthProvider sdk={auth}>
          {children}
        </AuthProvider>
      </StorageProvider>
    </FirestoreProvider>
  );
}

export default ReactFireProvider;
