import * as React from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { PiSignOutThin } from "react-icons/pi";
import { PiUserCircleThin } from "react-icons/pi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { useAuth, useSigninCheck } from 'reactfire';

import CircularChar from 'components/common/circularChar/CircularChar';

import useLocalStorage from 'hooks/useLocalStorage';
import { clearLocalStorage } from 'helpers/utils';

import { availableEntities } from 'constants/general';
import { PORTAL_REDIRECT_URL } from '../../constants/general';

import './Authentication.scss';

const UserDetails = ({ user, asLink }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    const { storedValue: currentUser } = useLocalStorage(availableEntities.CURRENT_USER);
    const {storedValue: refreshTokenIntervalId } = useLocalStorage(availableEntities.REFRESH_TOKEN_INTERVAL_ID);

    const goToMyAccount = () => {
        navigate('/account');
    };

    const signOut = async () => {
        try {
            await auth.signOut();
            clearLocalStorage();

            if (refreshTokenIntervalId) {
                clearInterval(refreshTokenIntervalId);
            }
            navigate('/');

        } catch (error) {
            console.error('Sign out - there was an error signing out', error);
        }
    };

    if (!currentUser) {
        signOut();
    }

    if (asLink) {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return (<a className='sign-out' onClick={() => signOut(auth)}>Sign Out</a>);
    }

    let firstCharName = user.displayName?.charAt(0) || user.email?.charAt(0);
    if (currentUser?.firstName) {
        firstCharName = currentUser.firstName?.charAt(0);
    }

    return (
        <div className='user-data-n-sign-out'>
            <Dropdown drop="left">
                <Dropdown.Toggle variant="success" id="dropdown-basic" as="div">
                    <CircularChar char={firstCharName} />
                </Dropdown.Toggle>
                <Dropdown.Menu align={"right"}>
                    <Dropdown.Item onClick={goToMyAccount}>
                        <PiUserCircleThin />
                        <span className='m-l-12'>Account</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => signOut(auth)}>
                        <PiSignOutThin />
                        <span className='m-l-12'>Sign Out</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export const SignOut = ({ btnText}) => {
    const auth = useAuth();
    const navigate = useNavigate();

    const signOut = async () => {
        try {
            await auth.signOut();
            clearLocalStorage();
            navigate('/login');

        } catch (error) {
            console.error('Sign out - there was an error signing out', error);
        }
    };

    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return (<a onClick={signOut} className='sign-out'>{btnText}</a>);
};


// const SignIn = ({ asLink = true }) => {
//     const whatsappNumber = "13617746077"; // Replace with your actual WhatsApp number
//     const message = encodeURIComponent("Hi, I'm interested in learning more about Anara Health");
//     const whatsappLink = `https://wa.me/${whatsappNumber}?text=${message}`;

//     const navigateToWhatsApp = () => {
//         window.open(whatsappLink, '_blank');
//     };

//     if (asLink) {
//         return (<Link to={whatsappLink} target="_blank" className='sign-in'>
//         <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '8px' }} />        
//         Chat on WhatsApp
//     </Link>);
//     }

//     return (        
//         <button onClick={navigateToWhatsApp} className='sign-up'>
//             <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '8px' }} />        
//             Chat on WhatsApp
//         </button>
        
//     );
// };

const SignUp = ({ asLink, btnText }) => {
    const location = useLocation();
    const PLANS = '/plans';

    const handleClick = (e) => {
        e.preventDefault();

        if (location.pathname !== PLANS) {
            window.open('https://calendly.com/anara-health/meet-with-dr-jason', '_blank');
        }
    };

    if (asLink) {
        return (
            <a 
                href='https://calendly.com/anara-health/meet-with-dr-jason' 
                onClick={handleClick} 
                className='sign-up' 
                style={{minWidth: '0ch'}}
                target="_blank"
                rel="noopener noreferrer"
            >
                {btnText}
            </a>
        );
    }

    return (
        <button onClick={handleClick} className='primary-button sign-up'>
            {btnText}
        </button>
    );
};

const Authentication = ({
    showAsLink = false,
    showSignIn = true,
    showSignUp = true,
    signUpBtnText = 'Book Consult',
    classes,
}) => {
    const { status, data: signinResult } = useSigninCheck();

    if (status === 'loading') {
        return <span>Loading...</span>;
    }

    const { signedIn, user } = signinResult;

    if (signedIn) {
        return <UserDetails user={user} asLink={showAsLink} />;
    }

    const whatsappNumber = "13617746077"; // Replace with your actual WhatsApp number
    const message = encodeURIComponent("Hi, I'm interested in learning more about Anara Health");
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${message}`;

    // Add this new condition
    if (window.location.pathname === '/portal') {
        return <Navigate to={PORTAL_REDIRECT_URL} replace />;
    }

    return (
        <div className={`Authentication ${classes}`}>
            <button className="whatsapp-chat-button" onClick={() => window.open(whatsappLink, '_blank')}>
            <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '1px' }} /> Chat
            </button>
            {showSignUp && <SignUp asLink={showAsLink} btnText={signUpBtnText} />}
        </div>
    );
};

export default Authentication;