// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import registeringUserReducer from './slices/registeringUserSlice';
import currentUserReducer from './slices/currentUserSlice';
import beneficiaryReducer from './slices/beneficiariesSlice';
import plansReducer from './slices/plansSlice';

export default configureStore({
  reducer: {
    registeringUser: registeringUserReducer,
    currentUserSlice: currentUserReducer,
    beneficiarySlice: beneficiaryReducer,
    plans: plansReducer,
  },
});
