import React from 'react';

const MenuButtonSvg = ({ width = 40, height = 40 }) => (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 20H29M11 14H29M11 26H29" stroke="#4B483F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

);

export default MenuButtonSvg;
