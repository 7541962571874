import { createSlice } from '@reduxjs/toolkit'

// const plan = {
//   id: '',
//   metadata: '',
//   default_price: '',
//   name: '',
//   description: '',
//   features: '',
// }

const initialState = [];

const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlans: (state, action) => {
      return [ ...state, ...action.payload ]
    },
    resetPlans: () => initialState,
  }
})

export const { setPlans, resetPlans } = plansSlice.actions

export default plansSlice.reducer
