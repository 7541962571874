/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { getAuth, onAuthStateChanged, getIdToken } from 'firebase/auth';

import { reactPlugin } from 'telemetry/appInsights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import '@stripe/stripe-js';

import AppMainContainer from 'components/common/appMainContainer/AppMainContainer';

import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import CustomSpinner from 'components/customSpinner/Spinner';

import {AUTO_REFRESH_TOKEN_TIME, DEVELOPMENT, INACTIVITY_TIME_AUTO_LOGOUT, availableEntities, PORTAL_REDIRECT_URL} from 'constants/general';

import useAutoSignOut from 'hooks/useAutoSignOut';
import useLocalStorage from 'hooks/useLocalStorage';

import { FeatureFlag } from 'constants/featureFlag';

import './App.scss';

const Home = lazy(() => import('pages/home/Home'));
const AboutUs = lazy(() => import('pages/aboutUs/AboutUs'));
const Login = lazy(() => import('pages/login/Login'));
const SignUp = lazy(() => import('pages/signUp/SignUp'));
const ForgotPassword = lazy(() => import('pages/forgotPassword/ForgotPassword'));
const CheckOutThankYou = lazy(() => import('pages/checkOutThankYou/CheckOutThankYou'));
const Plans = lazy(() => import('pages/plans/Plans'));
const Support = lazy(() => import('pages/support/Support'));
const AddBeneficiary = lazy(() => import('pages/addBeneficiary/AddBeneficiary'));
const AddOtherBeneficiary = lazy(() => import('pages/addOtherBeneficiary/AddOtherBeneficiary'));
const TermsAndConditions = lazy(() => import('pages/termsAndConditions/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('pages/privacyPolicy/PrivacyPolicy'));
const ContactUs = lazy(() => import('pages/contactUs/ContactUs'));
const Account = lazy(() => import('pages/account/Account'));
const Beneficiary = lazy(() => import('pages/beneficiary/Beneficiary'));
const Iitm = lazy(() => import('pages/iitm-landing/Iitm-alumni'));
const Iitd = lazy(() => import('pages/iitd-landing/Iitd-alumni'));
const Bcibn = lazy(() => import('pages/bcibn-landing/Bcibn'));
const Radio = lazy(() => import('pages/radio/Radio'));
const GenericLanding = lazy(() => import('pages/genericLanding/GenericLanding'));
const SponsorAgreement = lazy(() => import('pages/sponsorAgreement/SponsorAgreement'));
const NotFound = lazy(() => import('pages/notFound/NotFound'));
const Blogs = lazy(() => import('pages/blogs/Blogs'));
const ReferralLandingPage = lazy(() => import('pages/referralLandingPage/ReferralLandingPage'));
const ReferralSuccessPage = lazy(() => import('pages/referrallSuccessPage/ReferralSuccessPage'));

const PortalRedirect = () => {
  useEffect(() => {
    window.location.href = PORTAL_REDIRECT_URL;
  }, []);

  return (
    <div className="centered-spinner">
      <CustomSpinner text="Redirecting to portal..." />
    </div>
  );
};

function App() {
  const auth = getAuth();
  const {storedValue: currentUser, setLocalStorageValue: storeCurrentUserToLocalStorage } = useLocalStorage(availableEntities.CURRENT_USER);
  const {storedValue: refreshTokenIntervalId, setLocalStorageValue: storeRefreshTokenIntervalId } = useLocalStorage(availableEntities.REFRESH_TOKEN_INTERVAL_ID);

  // monitor user activity on browser and after 60 mins auto log out user
  useAutoSignOut(INACTIVITY_TIME_AUTO_LOGOUT);

  // refresh token
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        return;
      }

      // Check if HubSpot tracking is not blocked
    if (typeof window._hsq !== 'undefined') {
      // Load HubSpot chat widget
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.load();
      
        const status = window.HubSpotConversations.widget.status();
        if (status.loaded) {
          window.HubSpotConversations.widget.open();
        }
      }
    } else {
      console.log('HubSpot tracking is blocked. Chat widget will not be loaded.');
    }

      // User is signed in, refresh the token every hour
      if (!refreshTokenIntervalId) {
        // Clear any existing interval to prevent duplicates
        const intervalId = setInterval(async () => {
          const token = await getIdToken(user, true);
          // Set the refreshed token again to the current user so he can continue fetching data from BE
          storeCurrentUserToLocalStorage({...currentUser, idToken: token});
          if (process.env.NODE_ENV === DEVELOPMENT || process.env.REACT_APP_STAGING_ENV) {
            console.log('Token refreshed:', token);
          }
        }, AUTO_REFRESH_TOKEN_TIME);

        storeRefreshTokenIntervalId(intervalId);
  
        // Cleanup function to clear the interval when the component unmounts or user changes
        return () => clearInterval(intervalId);
      }
    });
  
    return () => unsubscribe();
  }, []);

  // TODO: remove initial redux effort and use @tanstack/react-query to improve the fetching data logic, cache management and avoid redux (you will thank me later)
  // TODO: use weblate for translations
  // TODO: eject and migrate the app to use vite as the dev engine
  // TODO: use JsDocs for improving type management (avoid TS, you will thank me later)

  return (
    <>
      <div className='App'>
        <Header />
        <AppMainContainer>
          <div className='content'>
          <Suspense fallback={<div className='centered-spinner'><CustomSpinner text={'Loading...'} /></div>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/plans" element={<Plans />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/support" element={<Support />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/account" element={<Account />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/thankyou" element={<CheckOutThankYou />} />
                <Route path="/termsandconditions" element={<TermsAndConditions />} />
                <Route path="/sponsoragreement" element={<SponsorAgreement />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/beneficiary/:id" element={<Beneficiary />} />
                <Route path="/iitm-alumni" element={<Iitm />} />
                <Route path="/iitd-alumni" element={<Iitd />} />
                <Route path="/bcibn" element={<Bcibn />} />
                <Route path="/sign-up" element={<GenericLanding />} />
                <Route path="/offer" element={<GenericLanding />} />
                <Route path="/radio" element={<Radio />} />
                <Route path="/beneficiary" element={<AddBeneficiary />} />
                <Route path="/otherbeneficiary" element={<AddOtherBeneficiary />} />
                <Route path="/referral" element={<ReferralLandingPage />} />
                <Route path="/referral-success" element={<ReferralSuccessPage />} />
                {FeatureFlag.blog && <Route path="/blog/*" element={<Blogs />} />}
                {FeatureFlag.blog && <Route path="/author/*" element={<Blogs />} />}
                <Route path="*" element={<NotFound />} />
                <Route path="/portal" element={<PortalRedirect />} />
              </Routes>
            </Suspense>
          </div>
        </AppMainContainer>
        <Footer />
      </div>
    </>
  );
}

export default withAITracking(reactPlugin, App);
