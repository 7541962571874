/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import './AppMainContainer.scss';

const AppMainContainer = ({children}) => {
    return (
        <div className="AppMainContainer">
            {children}
        </div>
    );
}

export default AppMainContainer;
