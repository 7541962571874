import React from 'react';

import './CircularChar.scss';

const CircularChar = ({char, action, extraClasses, iconClass=''}) => {
    const handleOnClick = () => {
        action?.();
    };

    let extraProps = {};

    if (action) {
        extraProps.onClick = handleOnClick;
    }

    return (
        <div className={`CircularChar ${action && 'cta'} ${extraClasses}`} {...extraProps}>
            <span className={`${iconClass}`}>
                {char}
            </span>
        </div>
    );
} 

export default CircularChar;
