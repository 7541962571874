import React from 'react';
import './Spinner.scss';

const CustomSpinner = ({ text }) => {
  return (
    <div className="custom-spinner">
      <div className="spinner"></div>
      {text && <p className="spinner-text">{text}</p>}
    </div>
  );
};

export default CustomSpinner;