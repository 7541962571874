/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

const  useLocalStorage = (key, initialState) => {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = () => {
    if (typeof window === 'undefined') {
      return initialState;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialState;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialState;
    }
  };

  // State to store our value
  const [storedValue, setStoredValue] = useState(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setLocalStorageValue = (value) => {
    if (typeof window == 'undefined') {
      console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
    }

    try {
      // Allow value to be a function so we have same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save state
      setStoredValue(newValue);

      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(newValue));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  // Function to remove a key from local storage
  const removeLocalStorageValue = () => {
    if (typeof window == 'undefined') {
      console.warn(`Tried removing localStorage key “${key}” even though environment is not a client`);
    }

    try {
      // Remove state
      setStoredValue(undefined);

      // Remove from local storage
      window.localStorage.removeItem(key);
    } catch (error) {
      console.warn(`Error removing localStorage key “${key}”:`, error);
    }
  };

  // Read the local item when the component mounts.
  useEffect(() => {
    setStoredValue(readValue());
  }, []);

  return {storedValue, setLocalStorageValue, removeLocalStorageValue};
}

export default useLocalStorage;
