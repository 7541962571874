import React from 'react';
import CustomSpinner from '../Spinner/Spinner';

import './Button.scss';

const Button = ({
    onClick,
    classes,
    onDoubleClick,
    onMouseOver,
    children,
    disabled = false,
    prebutton, // pre and post are intended to receive any jsx element to enhance the component
    postbutton,
    style,
    loading,
    buttonType,
}) => {
    const handleOnClick = (e) => {
        if (loading) {
            return true;
        }

        // Pass the event object to the onClick prop function
        onClick?.(e);
    }
    return (
        <>
            {prebutton}
            <button // TODO: extract conditional properties
                className={`button-class ${classes || ''} ${(loading || disabled) ? 'disabled' : ''}`}
                onClick={handleOnClick}
                disabled={disabled}
                onDoubleClick={onDoubleClick}
                onMouseOver={onMouseOver}
                style={style}
                type={buttonType || ''}
                >
                {!loading ? children : 'Loading...'}
                {loading && <CustomSpinner />}
            </button>
            {postbutton}
        </>
    );
};

export default Button;
